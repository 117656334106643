import { useUserStore } from "../stores/UserStore.js";
import { useLocaleStore } from "../stores/LocaleStore.js";

export default defineNuxtRouteMiddleware((to, from) => {
    if (useUserStore().isLoggedIn()) {
        const locale = useLocaleStore().prefix;
        const profileUrl = `/${locale}/profile/`;

        return navigateTo(profileUrl, { external: true });
    }
});
